import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { MsalProvider, useMsal } from "@azure/msal-react";
import "react-toastify/dist/ReactToastify.css";

const DeleteProfileButton = ({ id }) => {
  const navigateTo = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState(""); // New state to track text input
  const { instance } = useMsal();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setConfirmationText(""); // Clear the text when closing the modal
  };

  const deleteSwaps = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/swap/user/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        console.log("Swaps deleted successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error deleting swaps");
      });
  };

  const deleteUser = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/user/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        toast.success("User deleted successfully");
      })
      .catch((error) => {
        console.error(error);
        //toast.error("Error deleting user");
        toast.info("User deleted");
      });
  };

  const deleteChat = (id) => {
    axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/chats/delete/user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
        },
      }
    );
  };

  const handleDelete = () => {
    try {
      deleteSwaps(id);
      deleteUser(id);
      deleteChat(id);
      sessionStorage.clear();
      localStorage.clear();
      navigateTo("/");
    } catch (error) {
      console.error(error);
      //toast.error("Error deleting user");
      toast.info("User deleted");
    }

    closeModal(); // Close the modal after deletion
    //instance.logout(); // Log out the user
    toast.warn("User deleted successfully");
  };

  return (
    <>
      {/* Delete Button */}
      <div className="mb-4 md:mb-0 md:mr-4">
        <button
          onClick={openModal}
          className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813]"
        >
          Delete Account
        </button>
      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          <div className="z-50 bg-white p-8 rounded-lg shadow-lg max-w-md">
            <p className="text-lg font-semibold mb-4">Confirm Deletion</p>
            <p className="mb-4">
              Type <strong>"Delete my account"</strong> to confirm account
              deletion.
            </p>
            <input
              type="text"
              className="mb-4 p-2 border rounded-lg w-full"
              placeholder="Type here..."
              value={confirmationText} // Bind the value to the state
              onChange={(e) => setConfirmationText(e.target.value)} // Update state on change
            />
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full mr-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                disabled={confirmationText !== "Delete my account"} // Disable if text doesn't match
                className={`bg-[#e0211a] text-white px-4 py-2 rounded-full hover:bg-[#b41813] ${
                  confirmationText !== "Delete my account"
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteProfileButton;
