import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HowItWorks from "../components/HowItWorks";
import Hero from "../components/Hero";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import sha256 from "crypto-js/sha256";
import axios from "axios";
import { formatName } from "../utils/functions";

const Home = () => {
  const { accounts, instance } = useMsal();

  const updateLastLogin = async () => {
    console.log("updating last login");
    try {
      const userId = sessionStorage.getItem("user");
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/user/update/lastlogin/${userId}`,

        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
          },
        }
      );
    } catch (err) {
      console.error(err);
    }
  };
  const storeSessionToken = async () => {
    try {
      const accessTokenRequest = {
        scopes: ["user.read"], // Example scope, adjust as needed
        account: accounts[0], // Current logged-in account
      };

      const response = await instance.acquireTokenSilent(accessTokenRequest);

      if (response) {
        const token = response.accessToken; // Microsoft token
        sessionStorage.setItem("sessionToken", token); // Store in sessionStorage
      } else {
        console.error("Unable to acquire access token.");
      }
    } catch (err) {
      console.error("Error acquiring token silently:", err);
    }
  };

  const checkIfNewUser = () => {
    const hash = sha256(accounts[0].username).toString();
    let token;

    // check if the hash exists in the database api endpoint https://lucky-red-robe.cyclic.app/user/username/:hash
    axios

      .get(`${process.env.REACT_APP_SERVER_URL}/user/username/${hash}`)

      .then((res) => {
        // if it doesn't, then create a new user redirect to the profile page
        if (!res.data.data) {
          // create a new user
          sessionStorage.setItem("newUser", "true");
          sessionStorage.setItem("uuid", hash);
          // redirect to the profile page
          window.location.href = "/profile";
        } else {
          if (res.data.data.id) {
            sessionStorage.setItem("user", res.data.data.id);
            updateLastLogin();
            sessionStorage.setItem("role", res.data.data.role);
          } else if (res.data.data._id) {
            sessionStorage.setItem("user", res.data.data._id);
            updateLastLogin();
            sessionStorage.setItem("role", res.data.data.role);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Display a toast notification on successful log in
  useEffect(() => {
    if (
      accounts.length > 0 &&
      sessionStorage.getItem("WecomeToast") !== "true"
    ) {
      let name = formatName(accounts[0].name);
      toast.success(`Welcome back, ${name}`);
    }
    sessionStorage.setItem("WecomeToast", "true");
    checkIfNewUser();
    storeSessionToken();
    localStorage.clear();
  }, [accounts]);
  return (
    <>
      <Helmet>
        <title>StaffSwap</title>
        <meta name="description" content="StaffSwap" />
        <meta name="keywords" content="StaffSwap" />
        <meta name="author" content="StaffSwap" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />

      <Hero />

      <HowItWorks />

      <section className="bg-[#e0211a] text-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">Join StaffSwap Today!</h2>
          <p className="text-lg mb-8">
            Experience the convenience of seamless shift exchanges in the
            StaffSwap community.
          </p>
          <a
            href="/login"
            className="bg-white text-black py-2 px-4 rounded-full hover:bg-gray-400 no-underline"
          >
            Sign Up Now
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
