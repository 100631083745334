import { React, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DeleteProfileButton from "../components/DeleteProfile";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import "../index.css";
import roleData from "../data/roles.json";
import { useMsal } from "@azure/msal-react";
import { formatName } from "../utils/functions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const roles = roleData.roles;

const RadioButton = ({ label, isSelected, onChange }) => {
  const buttonStyle = {
    backgroundColor: isSelected ? "#e0211a" : "transparent",
    borderRadius: "20px",
    padding: "10px 20px",
    margin: "8px",
    color: isSelected ? "white" : "black",
    border: isSelected ? "none" : "1px solid #ccc",
  };
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default form submission
    onChange(label);
  };
  return (
    <button className="radio-button" style={buttonStyle} onClick={handleClick}>
      {label}
    </button>
  );
};

const RadioButtonList = ({ options, selectedOption, onChange }) => {
  return (
    <div className="radio-button-list">
      {options.map((option) => (
        <RadioButton
          key={option}
          label={option}
          isSelected={selectedOption === option}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
const Profile = () => {
  const navigate = useNavigate();

  const [threeLetterCode, setThreeLetterCode] = useState("");
  const [user, setUser] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const { accounts } = useMsal();

  // get the user data from the database

  useEffect(() => {
    let uuid = sha256(accounts[0].username).toString();
    console.log(accounts[0]);

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/user/username/${uuid}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
        },
      })
      .then((res) => {
        let data = res.data.data;

        if (data) {
          setThreeLetterCode(data.threeLetterCode);
          setSelectedOption(data.role);
          setUser(data);
          setUserExists(true);
        }
      });

    localStorage.clear();
  }, []);

  const handleRadioChange = (label) => {
    setSelectedOption(label);
  };

  // if newUser is false, then we are updating the user
  // create a function to handle the input change and save it to submitObject
  const handleInputChange = (event) => {
    const { value } = event.target;
    setThreeLetterCode(value);
  };

  const handleConfirmation = async () => {
    setConfirmation(false);
    let userID = sha256(accounts[0].username).toString();
    let name = formatName(accounts[0].name);
    let submitObject = {
      name: name,
      threeLetterCode: threeLetterCode,
      role: selectedOption,
      userID: userID,
    };

    axios

      .post(`${process.env.REACT_APP_SERVER_URL}/user/register`, submitObject, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("sessionToken")}`,
        },
      })

      .then((res) => {
        if (res.status === 201) {
          toast.success("Profile created successfully");

          sessionStorage.role = selectedOption;
          sessionStorage.removeItem("newUser");
          let user = res.data.data;
          sessionStorage.setItem("user", user.id);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Profile update failed");
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let validation = validate();
      if (!validation) {
        return;
      }
      let userID = sha256(accounts[0].username).toString();
      let name = formatName(accounts[0].name);

      let submitObject = {
        name: name,
        threeLetterCode: threeLetterCode,
        role: selectedOption,
        userID: userID,
      };

      if (!userExists) {
        setConfirmation(true);
      } else {
        // remove the threeLetterCode from the submitObject if it is the same as the user's threeLetterCode;
        if (submitObject.threeLetterCode === user.threeLetterCode) {
          delete submitObject.threeLetterCode;
        }
        axios

          .put(
            `${process.env.REACT_APP_SERVER_URL}/user/update/${user.id}`,
            submitObject,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                  "sessionToken"
                )}`,
              },
            }
          )

          .then((res) => {
            toast.success("Profile updated successfully");
            sessionStorage.role = selectedOption;
          })
          .catch((err) => {
            console.log("err", err);
            toast.error("Profile update failed");
          });
      }
    } catch (error) {
      console.log(error);
      toast.error("Profile update did not work");
    }
  };

  const validate = () => {
    if (threeLetterCode.length < 3) {
      toast.error("Please enter a three letter code");
      return false;
    }

    if (selectedOption === null) {
      toast.error("Please select a role");
      return false;
    }

    return true;
  };

  return (
    <>
      <Helmet>
        <title>StaffSwap | Profile</title>
        <meta name="description" content="StaffSwap" />
        <meta name="keywords" content="StaffSwap" />
        <meta name="author" content="StaffSwap" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />

      <div
        className="min-h-screen bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage: `url(/assets/hero_${
            sessionStorage.getItem("randomImage") || 1
          }.jpg)`,
        }}
      >
        {/* White rounded box */}
        <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-screen-md overflow-y-auto">
          {/* Your content goes here */}
          <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center text-center">
            Profile
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm mb-2 text-center">
                Name
              </label>
              <input
                type="text"
                id="text"
                name="name"
                value={formatName(accounts[0].name)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-center"
              />
            </div>

            {/* ThreeLetterCode */}
            <div className="mb-4">
              <label
                htmlFor="threeLetterCode"
                className="block text-sm mb-2 text-center"
              >
                Three Letter code
              </label>
              <input
                type="text"
                id="threeLetterCode"
                name="threeLetterCode"
                onChange={handleInputChange}
                maxLength={4} // Set maximum length to 4
                value={threeLetterCode.toUpperCase()}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-center"
              />
            </div>

            {/* Role */}
            <div className="mb-4">
              <label className="block text-sm mb-2 text-center">Role</label>
              <div className="flex justify-center items-center">
                {/* ... Radio buttons ... */}
                <div className="flex justify-center">
                  <RadioButtonList
                    options={roles}
                    selectedOption={selectedOption}
                    onChange={handleRadioChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813] justify-center mt-4"
              >
                {userExists ? "Update Profile" : "Create Profile"}
              </button>
            </div>
          </form>
          {userExists ? (
            <>
              <div className="mt-4 flex justify-center items-center">
                <button
                  onClick={() => navigate("/swap/my")} // Use navigate to redirect to "/my"
                  className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813] mt-4"
                >
                  Go to My Swaps
                </button>
              </div>

              <div className="mt-4 flex justify-center items-center">
                <DeleteProfileButton id={user.id} />
              </div>
            </>
          ) : null}
          <div className="mt-4 flex justify-center items-center">
            <p className="text-sm text-gray-500">
              <strong>Note:</strong> You can only swap with other users with the
              same role as you.
            </p>
          </div>

          {userExists ? (
            <div className="mt-4 flex justify-center items-center">
              <button
                onClick={() => navigate("/chat")} // Use navigate to redirect to "/chat"
                className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813] mt-4"
              >
                Go to Chat
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {confirmation ? (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-screen-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center">
              Confirm profile creation
            </h2>
            <p className="text-center">
              By clicking <strong>yes</strong>, you consent to creating a new
              account on StaffSwap and that you give permission to store and
              process your data. We do not share your data with third parties
              and only use it for the purpose of StaffSwap. You can delete your
              account at any time. More information can be found in our{" "}
              <a
                className="text-red-500 hover:text-red-700"
                href="/privacy"
                target="_blank"
              >
                privacy policy
              </a>
              .
            </p>
            <div className="flex justify-center items-center mt-4">
              <button
                onClick={handleConfirmation}
                className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813] mt-4"
              >
                Yes
              </button>
              <button
                onClick={() => setConfirmation(false)}
                className="bg-[#e0211a] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#b41813] mt-4 ml-4"
              >
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <Footer />
    </>
  );
};

export default Profile;
