import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserChats from "./UserChats";
import UserPanel from "./UserPanel";
import { ToastContainer } from "react-toastify";
import { checkNewUser } from "../../utils/functions";

function Sidebar() {
  const [firstVisit, setFirstVisit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (checkNewUser()) {
      navigate("/profile");
    }
    const hasVisitedBefore = sessionStorage.getItem("hasVisitedSidebar");
    if (!hasVisitedBefore) {
      setFirstVisit(true);
      sessionStorage.setItem("hasVisitedSidebar", "true");
    }
  }, []);

  useEffect(() => {
    if (checkNewUser()) {
      navigate("/profile");
    }
    if (firstVisit) {
      window.location.reload();
    }
  }, [firstVisit]);

  return (
    <div className="flex flex-col w-80 border-r border-divider h-screen">
      <ToastContainer draggable closeOnClick />
      <UserPanel />
      <UserChats />
    </div>
  );
}

export default Sidebar;
