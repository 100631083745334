import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Chat from "../Chat";
import Sidebar from "../Sidebar";
import MobileModal from "../Sidebar/MobileModal";
import { checkNewUser } from "../../utils/functions";

function ChatWrapper() {
  const navigate = useNavigate();
  useEffect(() => {
    if (checkNewUser()) {
      navigate("/profile");
    }
  }, []);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="flex h-screen">
      {isMobile ? <MobileModal /> : <Sidebar />}
      <Chat />
    </div>
  );
}

export default ChatWrapper;
