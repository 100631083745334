import moment from "moment";

export const getInitials = (name) => {
  const isSingleWord = name.split(" ").length === 1;

  if (isSingleWord) {
    return name[0];
  }

  return name.split(" ")[0][0] + name.split(" ")[1][0];
};

export const getOtherMember = (members, userId) => {
  return members.filter((member) => member.id !== userId)[0];
};

export const formatTime = (date) => {
  const hours = moment().diff(moment(date), "hours");
  return hours > 24
    ? moment(date).format("DD/MM/YY HH:mm")
    : moment(date).format("HH:mm");
};

export const formatName = (name) => {
  //  convert "KRUEGER, JASON" to "Jason Krueger"
  const nameArray = name.split(", ");
  // make the first letter of each name uppercase
  const formattedName = nameArray
    .reverse()
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
    .join(" ");
  return formattedName;
};

export const checkNewUser = () => {
  if (sessionStorage.getItem("newUser") === "true") {
    return true;
  }
  return false;
};
