import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/PublicHeader";

const Docs = () => {
  return (
    <>
      <Helmet>
        <title>StaffSwap | Documentation</title>
        <meta
          name="description"
          content="Contact page for StaffSwap, the independent Shift Exchange Platform for shift workers."
        />
        <meta
          name="keywords"
          content="StaffSwap, shift exchange, work platform, contact"
        />
        <meta name="author" content="StaffSwap" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen p-4 sm:p-6 md:p-8">
        <div className="text-4xl font-bold text-[#e0211a] mb-6">
          Documentatiom
        </div>

        <div className="max-w-[800px] text-lg text-[#333] leading-7">
          <p>All relevant Documentation can be found here</p>

          <ul className="list-disc mt-4 ml-6">
            <li>
              <a
                className="text-[#e0211a] hover:text-[#b41813] font-bold"
                href={process.env.PUBLIC_URL + "/docs/Readme.pdf"}
              >
                Readme
              </a>
            </li>
            <li>
              <a
                className="text-[#e0211a] hover:text-[#b41813] font-bold"
                href={process.env.PUBLIC_URL + "/docs/Privacy.pdf"}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                className="text-[#e0211a] hover:text-[#b41813] font-bold"
                href={process.env.PUBLIC_URL + "/docs/Usage.pdf"}
              >
                Usage
              </a>
            </li>

            <li>
              <a
                className="text-[#e0211a] hover:text-[#b41813] font-bold"
                href={process.env.PUBLIC_URL + "/docs/Container.html"}
              >
                Container Diagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Docs;
